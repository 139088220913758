.user {
    color: var(--color-green);
}

.light .user {
    color: var(--color-dark-green);
}

.tilde {
    color: var(--color-blue);

}

.light .tilde {
    color: var(--color-dark-blue);
}

.cursor {
    color: var(--color-white)
}

.light .cursor {
    color: var(--color-black);
}

.empty-span {
    font-size: 0;
    line-height: 1rem;
}

.line {
    display: flex;
    flex-wrap: wrap;
}

.file {
    color: var(--color-white);
}

.light .file {
    color: var(--color-black);
}

.folder {
    color: var(--color-blue);
}

.light .folder {
    color: var(--color-dark-blue);
}

span {
    white-space: break-spaces;
}

a {
    color: var(--color-blue);
}


a:hover {
    color: var(--color-white);
}

.light a:hover {
    color: var(--color-black);
}


/* Custom CSS for the textarea-based terminal */
.textarea-touch {
    border: none;
    resize: none;
    outline: none;
    background-color: var(--color-black);
    color: var(--color-white);
    margin: 0;
    padding: 0;
    width: 100%;
}

.textarea-touch:focus {
    border: none;
    outline: none;
}
