
#terminal {
    padding: 1rem;
}

.terminal-bg {
    background-color: var(--color-black);
    color: var(--color-white);
}

.terminal-bg.light {
    background-color: var(--color-white);
    color: var(--color-black);
}

.terminal-bg.matrix *, .terminal-bg.matrix {
    color: var(--color-green-matrix);
    background-color: black;
}


