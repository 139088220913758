.screen {
    background-color: transparent;
    display: flex;
    position: absolute;
    min-height: 50px;
    min-width: fit-content;
}

#desktop-screen {
    width: 100%;
    height: 100%;
    background-color: transparent;
}

.screen-folder {
    width: 50%;
    height: 50%;
    background-color: white;
    color: black;
    text-shadow: none;
    -webkit-border-top-left-radius: 5px;
    -webkit-border-top-right-radius: 5px;
    -moz-border-radius-topleft: 5px;
    -moz-border-radius-topright: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border: 5px solid var(--color-windows-xp-folder-dark-blue);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.folder-topbar {
    background-color: var(--color-windows-xp-folder-dark-blue);
    color: white;
    height: 25px;
    min-height: 25px;
    top: 0;
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modifier {
    cursor: pointer;
    width: 20px;
    height: 20px;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modifier:hover {
    filter: brightness(1.2);
}

.modifier:active {
    filter: brightness(0.8);
}

.folder-modifiers-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2px;
}

.folder-directory-info {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    font-family: "Trebuchet MS", serif;
}

.folder-directory-info img {
    width: 20px;
}


.minimize-folder {
}

.maximize-folder {
}

.close-folder {

}

.screen-content-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.screen-content {
    width: 100%;
    height: 100%;
}

.screen-content textarea {
    border: none;
    outline: none;
    resize: none;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

.pdf-viewer {
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.pdf-viewer-container {
    width: 100%;
    height: 100%;
}

.resize-handle {
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: transparent;
}

.resize-handle.top-left {
    top: -10px;
    left: -10px;
    cursor: nwse-resize;
}

.resize-handle.top-right {
    top: -10px;
    right: -10px;
    cursor: nesw-resize;
}

.resize-handle.bottom-left {
    bottom: -10px;
    left: -10px;
    cursor: nesw-resize;
}

.resize-handle.bottom-right {
    bottom: -10px;
    right: -10px;
    cursor: nwse-resize;
}

.resize-handle.top {
    top: -10px;
    width: 100%;
    cursor: ns-resize;
}

.resize-handle.bottom {
    bottom: -10px;
    width: 100%;
    cursor: ns-resize;
}

.resize-handle.left {
    left: -10px;
    height: 100%;
    cursor: ew-resize;
}

.resize-handle.right {
    right: -10px;
    height: 100%;
    cursor: ew-resize;
}

