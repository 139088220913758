#taskbar {
    height: 30px;
    position: fixed;
    bottom: 0;
    width: 100%;
    background: linear-gradient(to bottom,
    var(--color-windows-xp-taskbar-dark-blue) 0%,
    var(--color-windows-xp-taskbar-light-blue) 9%,
    var(--color-windows-xp-taskbar-dark-blue) 18%,
    var(--color-windows-xp-taskbar-dark-blue) 92%,
    #333 100%) center/cover no-repeat;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.start-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100px;
    cursor: pointer;
    text-shadow: none;
    -webkit-border-top-right-radius: 5px;
    -webkit-border-bottom-right-radius: 5px;
    -moz-border-radius-topright: 5px;
    -moz-border-radius-bottomright: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    gap: 5px;
    font-weight: bold;
    font-size: 1rem;
    box-shadow: 0 5px 10px #79ce71 inset;
    background: radial-gradient(circle,
    #5eac56 0%,
    #3c873c 100%) center/cover no-repeat;
}

.start-menu img {
    width: 20px;
    margin: 0;
}

.current-time {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 85px;
    height: 100%;
    text-shadow: none;
    background: linear-gradient(to bottom,
    var(--color-windows-xp-taskbar-light-blue) 0%,
    var(--color-windows-xp-taskbar-lightest-blue) 9%,
    var(--color-windows-xp-taskbar-light-blue) 18%,
    var(--color-windows-xp-taskbar-light-blue) 92%,
    #333 100%) center/cover no-repeat;
    display: flex;
}

.taskbar-folders-container {
    height: 100%;
    width: 100%;
    margin-left: 1rem;
    margin-right: 1rem;
    display: flex;
    justify-content: start;
    align-items: center;
}

.taskbar-folder {
    display: flex;
    justify-content: start;
    align-items: center;

    background: linear-gradient(to bottom,
    var(--color-windows-xp-taskbar-light-blue) 0%,
    #3f8cf3 9%,
    var(--color-windows-xp-taskbar-light-blue) 18%,
    var(--color-windows-xp-taskbar-light-blue) 92%, #333 100%) center/cover no-repeat;
    height: 70%;
    border-radius: 1px;
    gap: 0.5rem;
    overflow: clip;
    cursor: pointer;
}

.taskbar-folder:hover {
    filter: brightness(1.2);
}

.taskbar-folder.selected {
    filter: brightness(0.8);
}

.taskbar-folder img {
    height: 100%;
    margin: 0 0 0 0.5rem;
}
