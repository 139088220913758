.blobView {
    width: 50px;
    height: 50px;
    position: absolute;
    transform: translate(-50%, -50%); /* Center the blob based on its center position */
    cursor: pointer;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

}

img {
    margin: auto;
    display: block;

}


