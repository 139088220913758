.windows-bg {
    background: url("../../public/windows-xp/wallpaper.jpg");
    color: white;
    text-shadow: black 1px 1px 0px, black -1px -1px 0px, black -1px 1px 0px, black 1px -1px 0px;
}

#root {
    width: 100%;
}

#windows {
    font-family: Tahoma,Verdana,Segoe,sans-serif;
}

